import React, { Component } from 'react';
import inPortal from 'react-portal-decorator';
import { Wrapper, Content, Header, PullRight, Button } from './components';
import { FormWrapper } from '../general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { EPSILON } from '../../symbols';

class Modal extends Component {
	componentDidUpdate() {
		this._field.value = null;
	}
	render() {
		const { visible, title, message, data, handleSubmit, handleCancel } = this.props;
		return (
			<Wrapper visible={visible || false}>
				<Content>
					<Header>
						<strong>
							{title}
						</strong>
						<PullRight onClick={() => handleCancel(data)}>
							<FontAwesomeIcon icon={faTimesCircle} />
						</PullRight>
					</Header>
					<br />
					<small>{message}</small>
					<FormWrapper>
						<form onSubmit={(e) => {
							handleSubmit(this._field.value, data);
							return e.preventDefault();
						}}>
							<input ref={(elem) => { this._field = elem; }} placeholder={
								data
									? data.type === 'pos' ? 'label'
									: data.type === 'label' ? 'transition label'
									: data.data : null}/>
							{
								data && data.type === 'label' ? (
									<React.Fragment>
										<br />
										<Button
											onClick={() => handleSubmit('ε', data)}
										>
											epsilon ({EPSILON})
										</Button>
									</React.Fragment>
								) : null
							}
						</form>
					</FormWrapper>

				</Content>
			</Wrapper>
		);
	}
}

export default inPortal('modal')(Modal);