import React, { Component } from 'react';
import {
	withRouter
  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderCanvas from '../../canvasGraph';
import { Auth } from 'aws-amplify';
import { Left, LoginWrapper, Right, Header, DemoArea, DemoTop, Tile, DemoCanvas, Menu, MenuHeader, Result, FakeLink, FormButton, ErrorMessage} from './components';
import { FormWrapper } from '../../components';
import DFA from '../../dfa';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class Login extends Component {
	constructor(props) {
		super(props);
		const dfa = new DFA()
			.addNode('a')
			.addNode('b', true)
			.addNode('c')
			.addEdge('a', 'b', '0')
			.addEdge('a', 'c', '1')
			.addEdge('b', 'a', '0')
			.addEdge('b', 'c', '1')
			.addEdge('c', 'b', '0')
			.addEdge('c', 'a', '1')
			.start('a');

		this.state = {
			dfa,
			testString: '',
			success: dfa.acceptsNull(),
			waitingConfirm: false,
			signupVisible: true,
			usernameVal: '',
			passwordVal: '',
			passwordConfirmVal: '',
			emailVal: '',
			confirmationCodeVal: '',
		}
	}

	async getUser() {
		const res = await Auth.currentAuthenticatedUser();
		if (res.user) {
			this.props.history.push('/loggedin');
		}
	}

	handleValueChange(target, value) {
		const stateObj = {};
		stateObj[target] = value;
		this.setState(stateObj);
	}

	handleLogin() {
		this.setState({ loading: true, error: null });
		const username = this.state.usernameVal;
		const password = this.state.passwordVal;
		if (username && password) {
			Auth.signIn(username, password)
			.then(user => {
				this.props.history.push('/loggedin');
			})
			.catch((e) => {
				if (e && e.code === 'UserNotConfirmedException') {
					this.setState({ loading: false, error: 'You must confirm your account', waitingConfirm: true });
				} else {

				}
			});
		} else if (!username) {
			this.setState({ loading: false, error: 'Username is required' })
		} else if (!password) {
			this.setState({ loading: false, error: 'Password is required' })
		}
	}

	handleSignup() {
		this.setState({ loading: true, error: null });
		const username = this.state.usernameVal;
		const password = this.state.passwordVal;
		const passwordCopy = this.state.passwordConfirmVal;
		const email = this.state.emailVal;
		if (username && password && passwordCopy && email && password === passwordCopy) {
			window.localStorage.setItem('username', username);
			Auth.signUp({
				username,
				password,
				attributes: {
					email,
				},
				validationData: []
			}).then(() => {
				this.setState({ waitingConfirm: true, loading: false, error: null });
			}).catch((e) => {
				this.setState({ loading: false, error: e.message });
			});
		} else if (!username) {
			this.setState({ loading: false, error: 'Username is required' });
		} else if (!email) {
			this.setState({ loading: false, error: 'Email is required' });
		} else if (!password) {
			this.setState({ loading: false, error: 'Password is required' });
		} else if (!passwordCopy) {
			this.setState({ loading: false, error: 'Please retype your password' });
		} else if (password !== passwordCopy) {
			this.setState({ loading: false, error: 'Passwords do not match' });
		} else {
			this.setState({ loading: false, error: 'An unknown error occurred' });
		}
	}

	handleConfirm() {
		this.setState({ loading: true, error: null });
		const username = window.localStorage.getItem('username');
		const code = this.state.confirmationCodeVal;
		// After retrieveing the confirmation code from the user
		Auth.confirmSignUp(username, code, {
			// Optional. Force user confirmation irrespective of existing alias. By default set to True.
			forceAliasCreation: true    
		}).then((data) => {
			if (data === 'SUCCESS') {
				this.props.history.push('/loggedin');
				this.setState({ loading: false, error: null });
			} else {
				this.setState({ loading: false, error: 'Could not verify your code' });
			}
		}).catch(() => this.setState({ loading: false, error: 'Could not verify your code' }));
	}

	componentDidMount() {
		this.getUser();
		const { dfa } = this.state;
		const elem = this._canvasArea.getBoundingClientRect();

		renderCanvas('demoArea', elem, dfa);
		
	}

	handleInputChange({ target }) {
		const res = this.state.dfa.process(target.value || '');
		this.setState({ testString: target.value, success: res.accepted });
	}

	render() {
		const { testString, success, waitingConfirm, signupVisible, loading } = this.state;
		return (
			<LoginWrapper>
				<Left>
					<Header>Automaton Builder</Header>
					<Tile>
						Welcome!
						<br />
						<br />
						{/* TODO: Change this... */}
						This site can be used to do all sorts of things!
						<br />
						<br />
						Feel free to play with the demo to the right, or create an account/log in to save your designs!
					</Tile>
					<Tile>
						{
							signupVisible ? (
								<span>
									<strong>Create an account</strong> or <FakeLink onClick={() => this.setState({ signupVisible: false, waitingConfirm: false })}><strong>log in</strong></FakeLink>
								</span>
							) : (
								<span>
									<strong>Log in</strong> or <FakeLink onClick={() => this.setState({ signupVisible: true, waitingConfirm: false })}><strong>sign up</strong></FakeLink>
								</span>
							)
						}
						<br />
						<br />
						<FormWrapper>
							{this.state.error ? (
								<ErrorMessage>
									{this.state.error}
								</ErrorMessage>
							) : null}
							{
								waitingConfirm ? (
									<React.Fragment>
										<form onSubmit={(e) => {
											this.handleConfirm();
											return e.preventDefault();
										}}>
											We've sent you a confirmation code to your email. Please enter it below.
											<input value={this.state.confirmationCodeVal} onChange={(e) => this.handleValueChange('confirmationCodeVal', e.target.value)} placeholder="code"/>
											<FormButton type="submit">
												Validate
											</FormButton>
											{loading ? <FontAwesomeIcon spin icon={faSpinner} /> : ''}
										</form>
									</React.Fragment>
								) : signupVisible ? (
									<React.Fragment>
										<form onSubmit={(e) => {
											this.handleSignup();
											return e.preventDefault();
										}}>
											username:
											<input value={this.state.usernameVal} onChange={(e) => this.handleValueChange('usernameVal', e.target.value)} placeholder="username"/>
											email:
											<input value={this.state.emailVal} onChange={(e) => this.handleValueChange('emailVal', e.target.value)}  placeholder="you@yourdomain.com"/>
											password:
											<input value={this.state.passwordVal} onChange={(e) => this.handleValueChange('passwordVal', e.target.value)} type="password" placeholder="enter a password"/>
											confirm password:
											<input value={this.state.passwordConfirmVal} onChange={(e) => this.handleValueChange('passwordConfirmVal', e.target.value)} type="password" placeholder="retype your password"/>
											<FormButton type="submit">
												Sign up
											</FormButton>
											{loading ? <FontAwesomeIcon spin icon={faSpinner} /> : ''}
										</form>
									</React.Fragment>
								) : (
									<React.Fragment>
										<form onSubmit={(e) => {
											this.handleLogin();
											return e.preventDefault();
										}}>
											username:
											<input value={this.state.usernameVal} onChange={(e) => this.handleValueChange('usernameVal', e.target.value)} placeholder="username"/>
											password:
											<input value={this.state.passwordVal} onChange={(e) => this.handleValueChange('passwordVal', e.target.value)} type="password" placeholder="password"/>
											<FormButton type="submit">
												Log In
											</FormButton>
											{loading ? <FontAwesomeIcon spin icon={faSpinner} /> : ''}
										</form>
									</React.Fragment>
								)
							}
						</FormWrapper>
					</Tile>
				</Left>
				<Right>
					<DemoArea>
						<DemoTop></DemoTop>
						<DemoCanvas ref={(elem) => { this._canvasArea = elem; }} id="demoArea"/>
						<Menu>
							<MenuHeader>Test Strings</MenuHeader>
							<FormWrapper>
								<input placeholder="Σ = {0, 1}" value={testString} onChange={this.handleInputChange.bind(this)}/>
							</FormWrapper>
							<Result success={success}>The string '{testString}' is <strong>{success ? '' : 'not'}</strong> accepted</Result>
						</Menu>
					</DemoArea>
				</Right>
			</LoginWrapper>
		);
	}
}

export default withRouter(Login);