// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:502fc398-ea45-43bf-8b42-e473f07b7c7b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jAxl3lzJr",
    "aws_user_pools_web_client_id": "61tvna4llmjm98ic156s1pprkl"
};


export default awsmobile;
