import React from 'react';
import { Wrapper } from './components';

const Notifier = (props) => {
	const { visible, message } = props;
	return (
		<Wrapper visible={visible || false}>{message}</Wrapper>
	);
}

export default Notifier;