import styled from 'styled-components';
import theme from '../../config/theme';

export const Wrapper = styled.div`
	position: fixed;
	bottom: 20px;
	right: ${props => (props.visible ? '20px' : '-320px')};
	transition: right 0.7s;
	padding: 5px 10px;
	min-width: 200px;
	background-color: ${theme.colors.primary};
	height: 30px;
	box-shadow: 2px 3px 6px ${theme.colors.highlight};
	border-radius: 3px;
	color: white;
	word-wrap: break-word;
	display: flex;
	align-items: center;
`;