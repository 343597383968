import styled from 'styled-components';
import theme from '../../config/theme';

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	align-items: center;
	display: ${props => (props.visible ? 'flex' : 'none')};
`;

export const Content = styled.div`
	margin: 0 auto;
	width: 200px;
	background-color: ${theme.colors.primary};
	box-shadow: 2px 3px 6px ${theme.colors.highlight};
	border-radius: 3px;
	color: white;
	padding: 10px;
`;

export const Header = styled.div`
	display: flex;
`;

export const PullRight = styled.div`
	margin-left: auto;
	cursor: pointer;
`;

export const Button = styled.div`
	display: inline-block;
	padding: 5px;
	color: ${theme.colors.primary};
	background-color: white;
	border-radius: 4px;
	margin-left: auto;
	font-size: 0.8em;
	cursor: pointer;
`;
