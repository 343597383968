import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import Select from 'react-select';
import { Left, LoginWrapper, Right, Header, DemoArea, DemoTop, Tile, DemoCanvas, Menu, MenuHeader, Result, ButtonWrapper, DFAListItem, PullRight, Type, Load, Title, Example, Instruction, TopMenu, Loading, ActionSelector } from './components';
import { FormWrapper, Notifier, Modal } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSave, faArrowAltCircleRight, faPen, faTrashAlt, faMinusCircle, faSignOutAlt, faDotCircle, faSpinner} from '@fortawesome/free-solid-svg-icons'
import DFA from '../../dfa';
import renderCanvas from '../../canvasGraph';
import { post, get, del } from '../../utils';
import acceptingImg from './accepting.svg';
import startingImg from './starting.svg';
import selectedImg from './selected.svg';
import { SIGMA, EPSILON, INTERSECT, UNION } from '../../symbols';

class LoggedInCanvas extends Component {
	constructor(props) {
		super(props);
		const dfa = new DFA();
		this.state = {
			dfa,
			testString: '',
			success: dfa.acceptsNull(),
			structures: [],
			error: false,
			loading: true,
			name: 'Unnamed Automaton',
			stateAddMode: false,
			deleteMode: false,
			modalOpen: false,
			modalContent: '',
			modalHeader: '',
			modalData: null,
			selected: [],
			instructionsVisible: true,
			evaluatorVisible: true,
		}
	}

	componentDidMount() {
		const { dfa, deleteMode } = this.state;
		const elem = this._canvasArea.getBoundingClientRect();
		try {
			renderCanvas('canvasArea', elem, dfa, deleteMode, {
				getEdgeLabel: this.handleGetEdgeLabel.bind(this),
				update: () => this.forceUpdate(),
			});
		} catch (e) {
			this.handleLoadError();
		}
		this.getAll();
		document.addEventListener('resize', this.handleResize.bind(this));
	}

	handleResize() {
		this.forceUpdate();
	}

	componentWillUnmount() {
		document.removeEventListener('resize', this.handleResize.bind(this));
	}

	componentDidUpdate() {
		const { dfa, deleteMode } = this.state;
		const elem = this._canvasArea.getBoundingClientRect();
		try {
			renderCanvas('canvasArea', elem, dfa, deleteMode, {
				getEdgeLabel: this.handleGetEdgeLabel.bind(this),
				update: () => this.forceUpdate(),
			});
		} catch (e) {
			this.handleLoadError();
		}
	}

	handleInputChange({ target }) {
		const res = this.state.dfa.process(target.value || '');
		this.setState({ testString: target.value, success: res.accepted });
	}

	async handleSave() {
		this.setState({ status: `saving ${this.state.name}...` });
		let label = this.state.dfa.isNFA() ? 'NFA' : 'DFA';
		const res = await post('/user/data/save', {
			dfa: JSON.stringify(this.state.dfa.getStructure()).replace(/"/g, '\\"'),
			name: this.state.name,
			type: label,
		});

		const { data } = res;
		const { success } = data;
		if (success) {
			this.getAll();
			this.setState({ status: `saved ${this.state.name}!` });
		} else {
			this.setState({ status: 'failed!' });
		}
		setTimeout(() => {
			this.setState({ status: null });
		}, 2500);
	}

	async getAll() {
		this.setState({ loading: true });
		const res = await get('/user/data/all');
		const { data } = res;
		const { success, structures } = data;
		if (success) {
			await this.setState({ loading: false, structures, error: false });
			if (structures.length) {
				this.handleLoadDFA(0);
			}
		} else {
			this.setState({ loading: false, structures: [], error: true });
		}
	}

	async handleLoadError() {
		console.error('corrupted DFA');
	}

	handleLogout() {
		Auth.signOut()
			.then(() => window.location = '/')
			.catch(err => console.log(err));
	}

	handleLoadDFA(idx) {
			const { structures } = this.state;
			const aut = structures[idx];
			const { name, structure } = aut;
			try {
				this.setState(() => {
					const dfa = new DFA(JSON.parse(structure.replace(/\\"/g, '"')));
					return {
						dfa,
						testString: '',
						success: dfa.acceptsNull(),
						name,
					};
				});
			} catch (e) {
				this.handleLoadError();
			}

	}

	async handleGetEdgeLabel(from, to) {
		return new Promise((resolve, reject) => {
			this.setState(() => {
				return {
					modalOpen: true,
					modalHeader: 'Add Edge Label',
					modalContent: `Please add a label for ${from} => ${to}`,
					modalData: {
						type: 'label',
						data: {
							resolve,
							reject,
						},
					},
				};
			});
		})
	}

	async handleDeleteDFA(name) {
		this.setState({ status: 'deleting...' });
		const res = await del(`/user/data/delete/${encodeURIComponent(name)}`);
		const { data } = res;
		const { success, structures } = data;
		if (success) {
			this.setState({ structures, status: 'deleted!' });
		} else {
			this.setState({ status: 'failed!' });
		}
		setTimeout(() => {
			this.setState({ status: null });
		}, 2500);
	}

	render() {
		const { testString, success, selected } = this.state;
		const actions = [{
			value: 'intersection',
			label: 'Intersection',
			isDisabled: selected.length !== 2,
		}, {
			value: 'union',
			label: 'Union',
			isDisabled: selected.length !== 2,
		},{
			value: 'todfa', 
			label: `Convert to DFA`,
			isDisabled: selected.length !== 1,
		}, {
			value: 'eremoval',
			label: `${EPSILON} removal`,
			isDisabled: selected.length !== 1,
		}];

		return (
			<LoginWrapper>
				<Left>
					<Header>Automaton Builder</Header>
					<Tile flex>
						Logged in as&nbsp;<strong>{this.props.authData.username}</strong>
						<PullRight>
							<FontAwesomeIcon icon={faSignOutAlt} onClick={() => this.handleLogout()} />
						</PullRight>

					</Tile>
					{
						this.state.instructionsVisible ? (
							<Tile>
								<Title>
									Instructions
									<PullRight onClick={() => this.setState({ instructionsVisible: false })}>
										<FontAwesomeIcon icon={faMinusCircle} />
									</PullRight>
								</Title>
								On the right is the canvas area. Here, you can build automatons by adding nodes and edges.
								<br />
								<br />
								<Instruction>
									<Example alt="accepting state example" src={acceptingImg} />
									<div>
										<strong>Accepting states</strong>
										<br />
										Double click any state to toggle its acceptance.
									</div>
								</Instruction>
								<Instruction>
									<Example alt="starting state example" src={startingImg} />
									<div>
										<strong>Starting state</strong>
										<br />
										Right click any state to make it the start state.
									</div>
								</Instruction>
								<Instruction>
									<Example alt="selected state example" src={selectedImg} />
									<div>
										<strong>Interaction</strong>
										<br />
										Click any state to select it. Once highlighted, click any other state to 
										add an edge to it.
									</div>
								</Instruction>
								Strings can be evaluated at any time by entering into the box on the right of the canvas.
							</Tile>
						) : (
							<Tile>
								<Title>
									Show Instructions
									<PullRight onClick={() => this.setState({ instructionsVisible: true })}>
										<FontAwesomeIcon icon={faPlusCircle} />
									</PullRight>
								</Title>
							</Tile>
						)
					}
					<Tile>
						<Title>Your Automatons</Title>
						{
							this.state.loading ? (
								<Loading>
									<FontAwesomeIcon spin icon={faSpinner} /> 
								</Loading>
							) : this.state.structures.length === 0
								? (
									<Loading done>
										<FontAwesomeIcon icon={faSave} />
										<br />
										No automatons saved yet 
									</Loading>
								)
								: (
									<React.Fragment>
										<ActionSelector>
											<Select
												options={actions}
												placeholder="Select an action"
												className="actionSelector"
												value={null}
												onChange={async (e) => {
													if (e.value === 'todfa') {
														await this.setState((prevState) => {
															const { structures, selected } = prevState;
											
															const source = structures.find((str) => str.id === selected[0]);
															const { name, structure } = source;
															const sourceObj = new DFA(JSON.parse(structure.replace(/\\"/g, '"')));
															const result = sourceObj.enfaToDfa();
															return {
																dfa: result,
																name: `${name}-DFA`,
																selected: [],
															};
														});
														this.handleSave();
													} else if (e.value === 'intersection') {
														await this.setState((prevState) => {
															const { structures, selected } = prevState;
											
															const sourcel = structures.find((str) => str.id === selected[0]);
															const sourcer = structures.find((str) => str.id === selected[1]);
															
															const sourceObjl = new DFA(JSON.parse(sourcel.structure.replace(/\\"/g, '"')));
															const sourceObjr = new DFA(JSON.parse(sourcer.structure.replace(/\\"/g, '"')));

															const result = sourceObjl.intersection(sourceObjr);
															return {
																dfa: result,
																name: `${sourcel.name} ${INTERSECT} ${sourcer.name}`,
																selected: [],
															};
														});
														this.handleSave();
													} else if (e.value === 'union') {
														await this.setState((prevState) => {
															const { structures, selected } = prevState;
											
															const sourcel = structures.find((str) => str.id === selected[0]);
															const sourcer = structures.find((str) => str.id === selected[1]);
															
															const sourceObjl = new DFA(JSON.parse(sourcel.structure.replace(/\\"/g, '"')));
															const sourceObjr = new DFA(JSON.parse(sourcer.structure.replace(/\\"/g, '"')));

															const result = sourceObjl.union(sourceObjr);
															return {
																dfa: result,
																name: `${sourcel.name} ${UNION} ${sourcer.name}`,
																selected: [],
															};
														});
														this.handleSave();
													} else if (e.value === 'eremoval') {
														await this.setState((prevState) => {
															const { structures, selected } = prevState;
															const source = structures.find((str) => str.id === selected[0]);
															const { name, structure } = source;
															const sourceObj = new DFA(JSON.parse(structure.replace(/\\"/g, '"')));
															const result = sourceObj.removeEpsilons();
															return {
																dfa: result,
																name: `${name}-${EPSILON}-free`,
																selected: [],
															};
														});
														this.handleSave();
													}
												}}
											/>
										</ActionSelector>
									</React.Fragment>
								)
						}
						{this.state.structures.sort((a, b) => {
							if (!a.updated && b.updated) {
								return 1;
							}
							if (a.updated && !b.updated) {
								return -1;
							}
							if (!a.updated && !b.updated) {
								return 0;
							}
							const A = new Date(a.updated);
							const B = new Date(b.updated);
							return A > B ? -1 : A < B ? 1 : 0;
						}).map((structure, index) => {
							return (
								<DFAListItem key={structure.id}>
									<input type="checkbox" checked={this.state.selected.includes(structure.id)} onChange={(e) => {
										if (e.target.checked) {
											this.setState((prevState) => {
												const { selected } = prevState;
												selected.push(structure.id);
												return {
													selected,
												};
											});
										}
										else {
											this.setState((prevState) => {
												let { selected } = prevState;
												selected = selected.filter((item) => item !== structure.id);
												return {
													selected,
												};
											});
										}
									}}/>
									{structure.name}
									<PullRight>
										<Load onClick={() => this.handleDeleteDFA(structure.name)}><FontAwesomeIcon icon={faTrashAlt} /></Load>
										<Load onClick={() => this.handleLoadDFA(index)}><FontAwesomeIcon icon={faArrowAltCircleRight} /></Load>
										<Type>{structure.type}</Type>
									</PullRight>
								</DFAListItem>
							);
						})}
					</Tile>
				</Left>
				<Right>
					<TopMenu>
						<ButtonWrapper onClick={() => {
							this.setState({ 
								dfa: new DFA(),
								name: 'Unnamed Automaton',
								modalOpen: true,
								modalHeader: 'Change Save Name',
								modalData: {
									type: 'name',
									data: 'Unnamed Automaton',
								}
							});
						}}>
                            <FontAwesomeIcon icon={faPlusCircle} /> Create New
                        </ButtonWrapper>
						<ButtonWrapper active={this.state.stateAddMode} onClick={() => {
							this.setState((prevState) => {
								return {
									stateAddMode: !prevState.stateAddMode,
									deleteMode: false,
								};
							});
						}}>
                            <FontAwesomeIcon icon={faDotCircle} /> Insert State Mode
                        </ButtonWrapper>
						<ButtonWrapper active={this.state.deleteMode} onClick={() => {
							this.setState((prevState) => {
								return {
									stateAddMode: false,
									deleteMode: !prevState.deleteMode,
								};
							});
						}}>
                            <FontAwesomeIcon icon={faMinusCircle} /> Delete Mode
                        </ButtonWrapper>
						<ButtonWrapper onClick={() => this.handleSave()}>
                            <FontAwesomeIcon icon={faSave} />  Save
                        </ButtonWrapper>

					</TopMenu>
					<DemoArea stateAddMode={this.state.stateAddMode} deleteMode = {this.state.deleteMode} onClick={(e) => {
						if (this.state.stateAddMode) {
							const inner = this._canvasArea.getBoundingClientRect();
							const offsetX = inner.left;
							const offsetY = inner.top;
							const pos = {
								x: e.clientX - offsetX,
								y: e.clientY - offsetY,
							};
							this.setState((prevState) => {
								return {
									modalOpen: true,
									modalHeader: 'Add State Label',
									modalContent: 'Please add a label',
									modalData: {
										type: 'pos',
										data: pos,
									},
								};
							});
						}
					}}>
						<DemoTop onClick={() => {
							this.setState({
								modalOpen: true,
								modalHeader: 'Change Save Name',
								modalData: {
									type: 'name',
									data: this.state.name
								}
							})
						}}>{this.state.name} <FontAwesomeIcon icon={faPen} /></DemoTop>
						<DemoCanvas ref={(elem) => { this._canvasArea = elem; }} id="canvasArea"/>
						<Menu>
							{
								this.state.evaluatorVisible ? (
									<React.Fragment>
										<MenuHeader>
											Test Strings
											<PullRight
												onClick={() => {
													this.setState({ evaluatorVisible: false });
												}}
											>
												<FontAwesomeIcon icon={faMinusCircle} />
											</PullRight>
										</MenuHeader>
										<FormWrapper>
											<input placeholder={`${SIGMA} = ${this.state.dfa.getLanguage().reduce((acc, val) => {
												return acc !== '{' ? `${acc}, ${val}` : `${acc} ${val}`;
											}, '{')} }`} value={testString} onChange={this.handleInputChange.bind(this)}/>
										</FormWrapper>
										<Result success={success}>The string '{testString}' is <strong>{success ? '' : 'not'}</strong> accepted</Result>
									</React.Fragment>
								) : (
									<React.Fragment>
										<MenuHeader>
											Test Strings
											<PullRight
												onClick={() => {
													this.setState({ evaluatorVisible: true });
												}}
											>
												<FontAwesomeIcon icon={faPlusCircle} />
											</PullRight>
										</MenuHeader>
									</React.Fragment>
								)
							}
						</Menu>
					</DemoArea>
				</Right>
				<Notifier visible={!!this.state.status} message={this.state.status}/>
				<Modal
					visible={this.state.modalOpen}
					title={this.state.modalHeader}
					message={this.state.modalContent}
					data={this.state.modalData}
					handleSubmit={(val, data) => {
						if (data.type === 'pos') {
							this.setState((prevState) => {
								return {
									modalOpen: false,
									modalHeader: '',
									modalContent: '',
									dfa: prevState.dfa.addNode(val).setPosition(val, data.data),
								}
							});
						} else if (data.type === 'name') {
							this.setState(() => {
								return {
									modalOpen: false,
									modalHeader: '',
									modalContent: '',
									name: val,
								}
							});
						} else if (data.type === 'label') {
							data.data.resolve(val);
							this.setState(() => {
								return {
									modalOpen: false,
									modalHeader: '',
									modalContent: '',
								}
							});
						}
					}}
					handleCancel={(data) => {
						if (data.type === 'label') {
							data.data.resolve(null);
						}
						this.setState(() => {
							return {
								modalOpen: false,
								modalHeader: '',
								modalContent: '',
							}
						});
					}}
				/>
			</LoginWrapper>
		);
	}
}

export default withAuthenticator(LoggedInCanvas);