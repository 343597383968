/*
	This is the renderer for the DFAs to a canvas. Currently it works but does have
	the following caveats:

	- Only auto-layouts DFAs with 1, 2, 3, or 4 nodes. DFAs with more will all be rendered
		with the nodes on top of each other
	- Graphically, only supports two edges between each nodes. Essentially, at this point we only
		support langauges with at most two characters in the input alphabet
	- Does not support self-loops yet, graphically or in execution
*/

import Konva from 'konva';
import colorGen from 'color-scheme';
import theme from './config/theme';
import logdown from 'logdown';

const logger = logdown('Interaction');

const colors = new colorGen()
	.from_hue(150)
	.scheme('tetrade')
	.variation('soft')
	.colors();

function getColor(index) {
	return colors[index % colors.length];
}

function getNodes(dfa) {
	const structure = dfa.getStructure().dfa;
	return Object.keys(structure);
}

function countEdges(start, end, dfa) {
	const structure = dfa.getStructure().dfa;
	let count = 0;
	const first = structure[start];
	const firstEdges = first.edges;
	firstEdges.forEach((edge) => {
		if (edge.target === end) {
			count += 1;
		}
	});
	const last = structure[end];
	const lastEdges = last.edges;
	lastEdges.forEach((edge) => {
		if (edge.target === start) {
			count += 1;
		}
	});
	return count;
}

const interactionState = {
	selectedNode: null,
};

let doubleClickTime = 0;
const doubleClickThreshold = 200;

// Hard code the layout engine for now.
// TODO: come up with an algorithm for laying out
// the nodes for an arbitrary number
function getNodePos({ width, height }, index, count) {
	switch(count) {
	// two nodes
	case 2:
		return index === 0 ? {
			x: width / 2,
			y: height / 2 - 60,
		} : {
			x: width / 2,
			y: height / 2 + 60,
		}
	case 3:
		switch(index) {
			case 0:
				return {
					x: width / 2,
					y: height / 2 + 60,
				}
			case 1:
				return {
					x: width / 2 - 80,
					y: height / 2 - 60,
				}
			default:
				return {
					x: width / 2 + 80,
					y: height / 2 - 60,
				}
		}
	case 4:
		switch(index) {
			case 0:
				return {
					x: width / 2 + 80,
					y: height / 2 + 80,
				}
			case 1:
				return {
					x: width / 2 - 80,
					y: height / 2 - 80,
				}
			case 2:
				return {
					x: width / 2 + 80,
					y: height / 2 - 80,
				}
			default:
				return {
					x: width / 2 - 80,
					y: height / 2 + 80,
				}
		}
	case 5:
		switch(index) {
			case 0:
				return {
					x: width / 2 + 80,
					y: height / 2 + 80,
				}
			case 1:
				return {
					x: width / 2 - 80,
					y: height / 2 - 80,
				}
			case 2:
				return {
					x: width / 2 + 80,
					y: height / 2 - 80,
				}
			case 3:
				return {
					x: width / 2,
					y: height / 2,
				}
			default:
				return {
					x: width / 2 - 80,
					y: height / 2 + 80,
				}
		}
	default:
		const column = index % 3;
		const totalRows = Math.ceil(count / 3);
		const row = index % totalRows;
		const cx = width;
		const cy = height / 2;
		const sx = (cx - (totalRows / 2) * 95 - row * 40) / 2 ;
		const sy = (cy - (3 / 2) * 95) / 2;
		return {
			x: (sx + column * 95) % width,
			y: (sy + row * 95) % height,
		};
	}
}

// Calculates a parabola for the edge values, given a start and end node
// as a string - i.e. "a", "b", etc.
function getParabola(start, end) {
	// self loops...
	if (start.x === end.x && start.y === end.y) {
		return [
			start.x - 10, start.y,
			start.x - 40, start.y - 30,
			end.x + 10, end.y - 10,
		];
	} else {
		const mid = {
			x: (start.x + end.x) / 2,
			y: (start.y + end.y) / 2,
		};
	
		const p = {
			x: start.x - end.x,
			y: start.y - end.y,
		};
	
		const n = {
			x: -p.y,
			y: p.x,
		}
	
		const norm = Math.sqrt((n.x * n.x) + (n.y * n.y));
	
		n.x /= norm;
		n.y /= norm;
	
		const dist = 10;
		const points = [start.x, start.y, mid.x + (dist * n.x), mid.y + (dist * n.y), end.x, end.y];
		return points;
	}
	
}

// Calculates a start and end point for the edge values,
// given a start and end node as a string - i.e. "a", "b", etc.
function getEndpoints(nodeElems, start, end) {
	return {
		start: {
			x: nodeElems[start].getX(),
			y: nodeElems[start].getY(),
		},
		end: {
			x: nodeElems[end].getX(),
			y: nodeElems[end].getY(),
		}
	};
}

function createNodes(dfa, layer, stage, deleteMode, refs) {
	// Array of nodes as strings
	const nodes = getNodes(dfa);

	// Full DFA structure
	const structure = dfa.getStructure().dfa;

	// First, build all the nodes so we have a reference to their position.
	// Don't render them to the layer yet.
	const nodeElems = {};
	nodes.forEach((node, index) => {
		// See if a position has been stored in the structure
		// (this way the user can store the layout as well as the composition)
		let position = dfa.getPosition(node);
		if (!position) {
			const result = getNodePos({ width: stage.getWidth(), height: stage.getHeight() }, index, nodes.length);
			dfa.setPosition(node, result);
			position = dfa.getPosition(node);
		}

		const group = new Konva.Group({
			x: position.x,
			y: position.y,
			draggable: true,
		});
		const label = new Konva.Text({
			text: node,
			fill: 'white',
			align: 'center',
			verticalAlign: 'middle',
			fontFamily: 'monospace',
			// offsetX: 6,
			offsetY: 10,
			shadowColor: theme.colors.primary,
			shadowOffset: {
				x: 1,
				y: 1,
			},
			fontSize: 20,
		});
		const textWidth = label.getClientRect({ skipTransform: true }).width;
		label.offsetX(textWidth / 2);

		const circle = new Konva.Ellipse({
			radius: {
				y: 20,
				x: 20,
			},
			fill: `#${getColor(index)}`,
			stroke: node === interactionState.selectedNode 
				? '#66ffff'
				: dfa.isAccepting(node) 
					? 'orange'
					: theme.colors.secondary,
		});

		group.add(circle);
		group.add(label);
		if (dfa.isStart(node)) {
			const startTriangle = new Konva.RegularPolygon({
				sides: 3,
				radius: 10,
				fill: theme.colors.primary,
				offsetY: -30,
			});
			group.add(startTriangle);
		}

		nodeElems[node] = group;
	});

	// Next, create all the edges for the entire DFA and store references
	// to them, so when dragging a node we can update the dependent edges
	// Render them immediately to the layer, so they're on the bottom
	const allEdges = {};
	nodes.forEach((node) => {
		if (!allEdges[node]) {
			allEdges[node] = {
				out: {},
				in: {},
			};
		}
		const { edges } = structure[node];
		edges.forEach((edge) => {
			const numEdges = countEdges(node, edge.target, dfa);
			if (!allEdges[edge.target]) {
				allEdges[edge.target] = {
					out: {},
					in: {},
				};
			}
			const { start, end } = getEndpoints(nodeElems, node, edge.target);
			const points = getParabola(start, end, edge);
			
			const arrow = new Konva.Arrow({
				points: numEdges !== 1 ? points : [points[0], points[1], points[4], points[5]],
				pointerLength: 40,
				pointerWidth: 4,
				fill: theme.colors.primary,
				stroke: theme.colors.primary,
				strokeWidth: 1,
				tension: 0.3
			});
			
			const text = new Konva.Text({
				x: points[2],
				y: points[3],
				text: edge.input,
				fill: theme.colors.secondary,
				opacity: 1,
				shadowColor: 'white',
				shadowOffset: {
					x: 1,
					y: 1,
				},
				fontSize: 14,
				offsetX: 4,
				offsetY: 10,
				fontStyle: 'bold',
			});

			text.on('click', (e) => {
				if (deleteMode){
					dfa.deleteEdge(node, edge.target);
					refs.update();
				}
			});

			arrow.on('click', (e) => {
				if (deleteMode){
					dfa.deleteEdge(node, edge.target);
					refs.update();
				}
			})

			allEdges[node].out[`${node}_${edge.input}_${edge.target}`] = {
				arrow,
				text,
			};
			allEdges[edge.target].in[`${node}_${edge.input}_${edge.target}`] = {
				arrow,
				text,
			};
			layer.add(arrow);
			layer.add(text);
		});
	});

	// Now that all the edge dependencies have been calculated,
	// add a drag handler to each node. This handler will detect that a node is
	// being moved, and update the endpoints for all edges that either
	// are incoming or outgoing. Also updates the edge labels, since they
	// can't be in a group with the edge (yet) due to parabola complexities
	nodes.forEach((node) => {
		nodeElems[node].on('dragmove', () => {
			dfa.setPosition(node, {
				x: nodeElems[node].getX(),
				y: nodeElems[node].getY(),
			});
			// update incoming dependencies
			Object.keys(allEdges[node].in).forEach((edgeID) => {
				const parts = edgeID.split('_');
				const source = parts[0];
				const target = parts[2];
				const { start, end } = getEndpoints(nodeElems, source, target);
				const points = getParabola(start, end, dfa);
				const numEdges = countEdges(source, target, dfa);
				allEdges[node].in[edgeID].arrow.setPoints(numEdges !== 1 ? points : [points[0], points[1], points[4], points[5]]);
				allEdges[node].in[edgeID].text.setX(points[2]);
				allEdges[node].in[edgeID].text.setY(points[3]);
			})
			// update outgoing dependencies
			Object.keys(allEdges[node].out).forEach((edgeID) => {
				const parts = edgeID.split('_');
				const source = parts[0];
				const target = parts[2];
				const { start, end } = getEndpoints(nodeElems, source, target);
				const points = getParabola(start, end, dfa);
				const numEdges = countEdges(source, target, dfa);			
				allEdges[node].out[edgeID].arrow.setPoints(numEdges !== 1 ? points : [points[0], points[1], points[4], points[5]]);
				allEdges[node].out[edgeID].text.setX(points[2]);
				allEdges[node].out[edgeID].text.setY(points[3]);
			})
		});

		nodeElems[node].on('dblclick', (e) => {
			doubleClickTime = new Date();
			logger.log('double click');
			interactionState.selectedNode = null;
			dfa.accept(node, !dfa.isAccepting(node));
			refs.update();
		});

		nodeElems[node].on('click', (e) => {
			const t0 = new Date();
			if (t0 - doubleClickTime > doubleClickThreshold) {
				setTimeout(async () => {
					if (t0 - doubleClickTime > doubleClickThreshold) {
						logger.log('single click');
						if (interactionState.selectedNode && !deleteMode) {
							const result = await refs.getEdgeLabel(interactionState.selectedNode, node);
							// result will be null if the user cancels the dialog
							if (result) {
								dfa.addEdge(interactionState.selectedNode, node, result);
								interactionState.selectedNode = null;
								refs.update();
							} else {
								interactionState.selectedNode = null;
								refs.update();
							}
						} else {
							if (deleteMode){
								dfa.deleteNode(node);
								refs.update()
							} else {
								interactionState.selectedNode = node;
								refs.update();
							}
						}
					} else {
						interactionState.selectedNode = null;
						refs.update();
					}
					
				}, doubleClickThreshold);
			}
		});

		nodeElems[node].on('contextmenu', async (e) => {
			interactionState.selectedNode = null;
			dfa.start(node);
			refs.update();
			return e.evt.preventDefault();
		});

		// Finally, we can render the nodes :D
		layer.add(nodeElems[node]);
	})
}

export default function renderCanvas(canvasID, elem, dfa, deleteMode, refs) {
	// The stage is where all elements are rendered
	const stage = new Konva.Stage({
		container: canvasID,
		width: elem.width,
		height: elem.height,
	});

	// For now, only one layer. Not sure if we will need more in the future
	const layer = new Konva.Layer();

	// Create the entire DFA, but don't render it yet
	createNodes(dfa, layer, stage, deleteMode, refs);

	// Once everything is fully build, render the layer to the stage
	stage.add(layer);
}
