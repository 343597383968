import { Auth } from 'aws-amplify';
import Axios from 'axios';

export async function post(url, params) {
	const res = await Auth.currentSession().then(async (credentials) => {
		const request = await Axios.post(url, params, {
			headers: {
				Authorization: credentials.accessToken.jwtToken,
				"Access-Control-Allow-Credentials": true,
			}
		});
		return request;
	});
	return res;
}

export async function get(url) {
	const res = await Auth.currentSession().then(async (credentials) => {
		const request = await Axios.get(url, {
			headers: {
				Authorization: credentials.accessToken.jwtToken,
				"Access-Control-Allow-Credentials": true,
			}
		});
		return request;
	});
	return res;
}

export async function del(url) {
	const res = await Auth.currentSession().then(async (credentials) => {
		const request = await Axios.delete(url, {
			headers: {
				Authorization: credentials.accessToken.jwtToken,
				"Access-Control-Allow-Credentials": true,
			}
		});
		return request;
	});
	return res;
}
