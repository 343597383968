export default {
	colors: {
		primary: '#232323',
		secondary: '#6EB257',
		alt: '#488B49',
		var1: '#fffbf7',
		highlight: '#686767',
		warning: '#f45a5a',
	},
	typeface: {
		primary: '#fffbf7',
		secondary: '#232323',
	},
}