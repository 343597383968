import styled from 'styled-components';
import theme from '../../config/theme';
import { GeneralTile } from '../../components';

export const LoginWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

export const Left = styled.div`
	width: 50%;
	max-width: 300px;
	background-color: ${theme.colors.primary};
	height: 100%;
	box-shadow: 1px 0 12px ${theme.colors.alt};
	z-index: 2;
	overflow-y: scroll;
`;

export const Right = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${theme.colors.secondary};
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
`;

export const Header = styled.div`
	width: 100%;
	color: ${theme.typeface.primary};
	text-align: center;
	font-size: 2em;
	padding-top: 20px;
`;

export const DemoArea = styled.div`
	width: 90%;
	height: calc(90% - 30px);
	margin: 0 auto;
	background-color: white;
	border-radius: 8px;
	box-shadow: 2px 3px 6px ${theme.colors.highlight};
	border: 4px solid ${theme.colors.primary};
	position: relative;
	cursor: ${props => (props.stateAddMode ? 'copy' : props.deleteMode ? 'crosshair' : 'pointer')}
`;

export const DemoTop = styled.div`
	width: calc(100% - 10px);
	height: 20px;
	background-color: ${theme.colors.primary};
	color: white;
	line-height: 100%;
	padding-left: 10px;

	& svg {
		font-size: 0.8em;
		transform: TranslateY(-2px);
		margin-left: 5px;
	}
`;

export const Tile = styled(GeneralTile)`
	color: ${theme.typeface.secondary};
	background-color: ${theme.colors.var1};
	margin: 15px;
	border-radius: 4px;

	& a {
		color: ${theme.colors.secondary};
		text-decoration-style: dotted;
	}
`;

export const DemoCanvas = styled.div`
	width: 100%;
	height: calc(100% - 20px);
`;

export const Menu = styled.div`
	position: absolute;
	top: 40px;
	right: 20px;
	background-color: ${theme.colors.primary};
	box-shadow: 2px 3px 6px ${theme.colors.highlight};
	border-radius: 3px;
	min-width: 200px;
	max-width: calc(100% - 20px);
	padding: 10px;
	color: white;
	width: 20%;
	word-wrap: break-word;
`;
 export const ButtonWrapper = styled.div`
	padding:  10px;
	cursor: pointer;
	transition: color 0.5s;
	color: ${props => (props.active ? theme.colors.secondary : 'inherit')};
 `;

export const MenuHeader = styled.div`
	color: white;
	width: 100%;
	text-align: center;
	align-items: center;
	padding: 2px 2px;
	font-size: 1.2em;
	display: flex;

	& svg {
		font-size: 0.8em;
	}
`;

export const Result = styled.div`
	color: ${props => (props.success ? theme.colors.secondary : 'red')};
`;

export const AccountInfo = styled.div`
	cursor: pointer;
`;

export const DFAListItem = styled.div`
	margin: 10px 5px;
	padding: 5px;
	border: 1px solid grey;
	border-radius: 4px;
	border-left: 3px solid ${theme.colors.secondary};
	box-shadow: 1px 1px 6px lightgrey;
	display: flex;
	align-items: center;
`;

export const PullRight = styled.div`
	margin-left: auto;
	display: flex;
	align-items: center;
	cursor: pointer;

	& svg {
		color: inherit;
		transition: color 0.4s;
	}

	& svg:hover {
		color: ${theme.colors.secondary};
	}
`;

export const Type = styled.span`
	font-size: 0.8em;
	color: white;
	background-color: lightgrey;
	border-radius: 4px;
	padding: 2px 4px;
`;

export const Load = styled.span`
	color: grey;
	padding-right: 5px;
	cursor: pointer;

	&:hover {
		color: ${theme.colors.secondary};
	}
`;

export const Title = styled.div`
	display: flex;
	width: 100%;
	font-weight: 700;
`;

export const Example = styled.img`
	width: 25px;
	padding-right: 10px;
`;

export const Instruction = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 10px;
`;

export const TopMenu = styled.div`
	width: calc(90% - 10px);
	margin: 10px auto;
	background-color: ${theme.colors.primary};
	border-radius: 8px;
	box-shadow: 2px 3px 6px ${theme.colors.highlight};
	border: 4px solid ${theme.colors.primary};
	color: white;
	padding: 5px;
	display: flex;
	height: 40px;
`;

export const Loading = styled.div`
	width: 100%;
	text-align: center;
	padding-top: 10px;
	color: ${props => (props.done ? 'lightgrey' : theme.colors.secondary)};
`;

export const ActionSelector = styled.div`
	width: 100%;
	& .actionSelector {
		margin: 10px 5px;
		font-size: 0.8em;
		border: 1px solid grey;
		border-radius: 4px;
		box-shadow: 1px 1px 6px lightgrey;
	}
`;