import styled from 'styled-components';
import theme from '../../config/theme';
import { GeneralTile } from '../../components';

export const LoginWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

export const Left = styled.div`
	width: 50%;
	max-width: 400px;
	background-color: ${theme.colors.primary};
	height: 100%;
	box-shadow: 1px 0 12px ${theme.colors.alt};
	z-index: 2;
`;

export const Right = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${theme.colors.secondary};
	display: flex;
	align-items: center;
`;

export const Header = styled.div`
	width: 100%;
	color: ${theme.typeface.primary};
	text-align: center;
	font-size: 2em;
	padding-top: 20px;
`;

export const DemoArea = styled.div`
	width: 80%;
	height: 80%;
	margin: 0 auto;
	background-color: white;
	border-radius: 8px;
	box-shadow: 2px 3px 6px ${theme.colors.highlight};
	border: 4px solid ${theme.colors.primary};
	position: relative;
`;

export const DemoTop = styled.div`
	width: 100%;
	height: 20px;
	background-color: ${theme.colors.primary};
`;

export const Tile = styled(GeneralTile)`
	color: ${theme.typeface.secondary};
	background-color: ${theme.colors.var1};
	margin: 15px;
	border-radius: 4px;

	& a {
		color: ${theme.colors.secondary};
		text-decoration-style: dotted;
	}
`;

export const DemoCanvas = styled.div`
	width: 100%;
	height: calc(100% - 20px);
	border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
`;

export const Menu = styled.div`
	position: absolute;
	top: 40px;
	right: 20px;
	background-color: ${theme.colors.primary};
	min-height: 200px;
	box-shadow: 2px 3px 6px ${theme.colors.highlight};
	border-radius: 3px;
	min-width: 200px;
	max-width: calc(100% - 20px);
	padding: 10px;
	color: white;
	width: 20%;
	word-wrap: break-word;
`;

export const MenuHeader = styled.div`
	color: white;
	widrth: 100%;
	text-align: center;
	padding: 5px;
	font-size: 1.2em;
`;

export const Result = styled.div`
	color: ${props => (props.success ? theme.colors.secondary : 'red')};
`;

export const FakeLink = styled.span`
	color: ${theme.colors.secondary};
	text-decoration: underline;
	text-decoration-style: dotted;
	cursor: pointer;
`;

export const FormButton = styled.button`
	padding: 8px 10px;
	border-radius: 4px;
	background-color: ${theme.colors.secondary};
	color: white;
	margin-top: 10px;
	display: inline-block;
	cursor: pointer;
	transition: transform 0.5s;
	transform: Scale(1);
	border: none;

	&:hover {
		transform: Scale(1.05);
	}
`;

export const ErrorMessage = styled.div`
	margin: 10px 0;
	background-color: ${theme.colors.warning};
	color: white;
	border-radius: 4px;
	padding: 5px;
	width: 90%;
`;

export const ButtonWrapper = styled.div`
padding:  10px;
cursor: pointer;
transition: color 0.5s;
color: ${props => (props.active ? theme.colors.secondary : 'inherit')};
`;
