import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as Screens from './screens';
import { AppWrapper } from './components';

class App extends Component {
  render() {
    return (
        <Router>
              <AppWrapper>
                    <Route path="/" exact component={Screens.Login} />
                    <Route path='/loggedIn' component={Screens.LoggedInCanvas}/>
              </AppWrapper>
        </Router> 
    );
  }
}

export default App;
