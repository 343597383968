import styled from 'styled-components';
import theme from '../config/theme';

export const AppWrapper = styled.div`
	width: 100vw;
	height: 100vh;

	& > div {
		width: 100%;
		height: 100%;
	}
`;

export const GeneralTile = styled.div`
	padding: 10px;
	${props => (props.flex ? `
		display: flex;
	` : ``)}
`;

export const FormWrapper = styled.div`
	& svg {
		color: ${theme.colors.secondary}
		margin-left: 10px;
	}
	& input {
		padding: 5px;
		margin: 5px 0;
		width: 90%;
		border-radius: 4px;
		border: 1px solid ${theme.colors.primary};
		height: 20px;
	}
`;

export const PullRight = styled.div`
	margin-left: auto;
`;

